export const MAPPING_TABLE_COLS = ["tenant",
"view",
"table",
"connector",
"source_config",
"pull_type",
"replace",
"schedule_interval",
"filter",
"extraction_sync_dt",
"source_format_regex",
"partition_column",
"clustering_columns",
"query_partitioning_threshold",
"field_delimiter",
"dataingestion_filterparam",
"num_partitions",
"db_partition_column",
"replace_special_characters",
"row_count_validation_info",
"filter_param",
"intermediate_table", 
"inter_query_exec_order"
]

export const GENERIC_MAPPING_TABLE_COLS = [
    "source_table",
    "source_level",
    "generic_mapping_table",
    "destination_table",
    "destination_level",
    "to_be_ingested",
    "is_required_in_master",
    "persist_attributes_table",
    "persist_hierarchies_table",
    "cross_validations",
    "pull_type",
    "persist_to_postgres",
    "data_loss_threshold"
]

export const GENERIC_SCHEMA_MAPPING_TABLE_COLS = [
    "source_column_name",
    "source_column_datatype",
    "required_in_product",
    "generic_column_name",
    "is_pk",
    "generic_column_datatype",
    "formula",
    "is_attribute",
    "is_hierarchy",
    "hierarchy_level",
    "is_null_allowed",
    "unique_by",
    "display_name",
    "is_partition_col",
    "is_clustering_col"
]

export const DERIVED_TABLE_COLS = [
    "name",
    "run_in",
    "replace_flag_gbq",
    "replace_flag_psg",
    "execution_order",
    "type",
    "schedule_interval"
]

export const RULE_MASTER_COLS = [
    "agg",
    "value",
    "kpis_info",
    "table",
    "outer_filter",
    "action",
    "name",
    "group_by",
    "inner_filter",
    "module",
    "threshold",
    "rule",
    "rule_display_name",
    "rule_description",
    "kpis"
]

export const KPI_MASTER_COLS = [
    "kpicode",
    "kpi",
    "query",
    "variable",
    "table",
    "level"
]

export const TRIGGER_COLS = [
    "view",
    "source_config",
    "connector",
    "trigger_rule",
    "trigger_query",
    "trigger_query_filter",
    "trigger_file",
    "is_mandatory"   
]